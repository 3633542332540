<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :is-edit="isEdit"
      ref="edit_field"
      :fields="fields"
      :row-data="row_data"
      post-api="/kioskupdate/UpgradeKiosk"
      finish-router="kioskManageVersion"
      :submitAction="submitAction"
      permission="Kiosk_Menu_Version"
    >
      <template #facility="scope">
        <slot-facility
          ref="slot_facility"
          :companyId="scope.rowData.companyID"
          :facilityId="scope.rowData.facilityID"
          :row-data="scope.rowData"
          :is-edit="scope.isEdit"
        />
      </template>
    </edit-field>

  </page-content>
</template>

<script>
import fields, { reloadFieldData, reloadTargetVersionOptions } from './fields'
import EditField from '@/components/EditField2'
import PageContent from '@/components/PageContent'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import SlotFacility from './SlotFacility'
import fun from '@/views/group/fun.js'
// import _ from 'lodash'

export default {
  name: 'kioskUpgradeOne',
  components: {
    SlotFacility,
    EditField,
    EditFieldHeader,
    PageContent,
    fields,
  },
  data() {
    return {
      title: common.getMenuName('kioskManageVersion') + ' - ' + common.getI18n('kiosk.upgrade'),
      show_modal_bed: false,
      isEdit: true,
      fields,
      row_data: {
        kioskToken: common.decrypt(this.$router.currentRoute.params.token),
        kioskName: null,
        platform: common.decrypt(this.$router.currentRoute.params.platform),
        upgradeRange: 'custom',
        packageID: null,
        companyID: null,
        facilityID: null,
      },
    }
  },

  async created() {
    const res = await common.apiGetData(`/kioskupdate/GetKiosk?kioskToken=${this.row_data.kioskToken}`)
    const kiosk = res.data?.kiosk
    this.$set(this.row_data, 'kioskName', kiosk.kioskName)
    this.$set(this.row_data, 'companyID', kiosk.companyID)
    this.$set(this.row_data, 'facilityID', kiosk.facilityID)
    console.log(this.row_data)
  },
  async mounted() {
    await reloadFieldData(this.isEdit)
    if ([this.row_data.kioskToken, this.row_data.platform].includes(undefined)) {
      await this.$router.replace({ path: '/' + this.$route.path.split('/')[1] })
      return false
    } else {
      await reloadTargetVersionOptions(this.$refs['edit_field'])
    }
  },

  methods: {
    addBed: function () {
      fun.addBed(this)
      this.$refs.model_bed.checkSelectAll()
    },
    changeBed: function (type, option) {
      fun.changeBed(this, type, option)
    },
    addMember: function () {
      fun.addMember(this)
      this.$refs.model_member.checkSelectAll()
    },
    changeMember: function (type, option) {
      fun.changeMember(this, type, option)
    },

    submitAction(rowData, postApi, finishRouter) {
      const that = this

      const platform = this.row_data.platform
      // const devices = fields.find(f => f.name === 'upgradeRange').options.find(o => o.value === that.row_data.upgradeRange).text
      const device = this.row_data.kioskName
      const facility = this.$refs['slot_facility']?.options.find(option => option.value === that.row_data.facilityID).text
      console.log(facility)
      const title = `升級 "${platform}" 平台下 "${facility ?? '未綁定院舍'}" 的 "${device}"？`
      common.showConfirm({
        title,
        confirm_fun: submit,
      })

      function submit() {
        that.$requestWehealth({
          url: postApi,
          method: 'post',
          data: {
            'PackageID': rowData.packageID,
            'IsUpgradeAll': rowData.upgradeRange === 'all',
            'KioskTokens': [rowData.kioskToken],
          },
        }).then((res) => {
          that.$router.push({ name: finishRouter })
        })
      }
    },

    changeBedFacilityId: function (facilityID) {
      this.$refs.model_bed.changeFacilityId(facilityID)
    },
    changeMemberFacilityId: function (facilityID) {
      this.$refs.model_member.changeFacilityId(facilityID)
    },
  },
}
</script>

<style scoped>

</style>
