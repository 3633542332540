export default {
  addBed: function (obj) {
    let bed_ids = []
    for (const i in obj.$refs.slot_bed.options) {
      bed_ids.push(obj.$refs.slot_bed.options[i].bedID)
    }

    obj.$refs.model_bed.bed_ids = bed_ids

    obj.$refs.model_bed.show_modal = true
  },

  changeBed: function (obj, type, option) {
    if (type === 'add') {
      obj.$refs.slot_bed.options.push(option)
    } else if (type === 'delete') {
      for (const i in obj.$refs.slot_bed.options) {
        if (obj.$refs.slot_bed.options[i].bedID === option.bedID) {
          obj.$refs.slot_bed.options.splice(i, 1)
          break
        }
      }
    }
  },

  addMember: function (obj) {
    let member_ids = []
    for (const i in obj.$refs.slot_member.options) {
      member_ids.push(obj.$refs.slot_member.options[i].memberID)
    }

    obj.$refs.model_member.member_ids = member_ids
    obj.$refs.model_member.show_modal = true
  },

  changeMember: function (obj, type, option) {
    if (type === 'add') {
      obj.$refs.slot_member.options.push(option)
    } else if (type === 'delete') {
      for (const i in obj.$refs.slot_member.options) {
        if (obj.$refs.slot_member.options[i].memberID === option.memberID) {
          obj.$refs.slot_member.options.splice(i, 1)
          break
        }
      }
    }
  },
}
