import common from '@/common'

const kioskUtils = {
  getKioskEnvOptions() {
    return common.apiGetData('/kioskmanage/GetKioskEnvOptions')
      .then(res => {
        const environments = res.data.environments ?? res.data.environements ?? []
        const registers = res.data.registers ?? [true, false];
        const [envOptions, platformOptions, registerOptions] = [[], [], []]
        environments.forEach(env => {
          envOptions.push({
            text: env,
            value: env,
          })
        })
        res.data.platforms.forEach(platform => {
          platformOptions.push({
            text: platform,
            value: platform,
          })
        })
        registers.forEach(isRegistered => {
          registerOptions.push({
            text: isRegistered ? common.getI18n('common.yes') : common.getI18n('common.no'),
            value: isRegistered,
          })
        })
        return { envOptions, platformOptions, registerOptions }
      })
  },
  deviceModes: [
    {
      value: 'Free',
      text: common.getI18n('kiosk.free')
    },
    {
      value: 'Fixed',
      text: common.getI18n('kiosk.fixed')
    },
  ],
  purposeOpts: [
    {
      value: 'BodyTemp',
      text: common.getI18n('kiosk.temperature'),
      isFixable: true, // 可固定
    },
    {
      value: 'BP',
      text: common.getI18n('kiosk.blood_pressure'),
      isFixable: true,
    },
    {
      value: 'BO',
      text: common.getI18n('kiosk.oximetry'),
      isFixable: true,
    },
    {
      value: 'Weight',
      text: common.getI18n('kiosk.weight'),
      isFixable: true,
    },
    {
      value: 'Height',
      text: common.getI18n('kiosk.height'),
      isFixable: true,
    },
    {
      value: 'Glucose',
      text: common.getI18n('kiosk.blood_glucose'),
      isFixable: true,
    },
    {
      value: 'GripStrength',
      text: common.getI18n('kiosk.grip_strength'),
      isFixable: true,
    },
    {
      value: 'NfcReader',
      text: common.getI18n('kiosk.nfc_reader'),
      isFixable: true,
    },
    {
      value: 'Printer',
      text: common.getI18n('kiosk.printer'),
      isFixable: false,
    },
  ],
}

kioskUtils.purposes = kioskUtils.purposeOpts.map(p => p.value);
kioskUtils.fixablePurposeOpts = kioskUtils.purposeOpts.filter(p => p.isFixable);
kioskUtils.fixablePurposes = kioskUtils.fixablePurposeOpts.map(p => p.value);

export default kioskUtils;
